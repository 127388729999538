import React from 'react';

import { Translations } from './en';

export const de: Translations = {
  aborted: 'Abgebrochen',
  accelerationDescription:
    'Der Beschleunigungsfaktor wird für die Berechnung der Fahrkurve verwendet',
  accelerationNote:
    'Diese Einstellung muss groß genug sein um die zu eingestellten Türgeschwindigkeiten tatsächlich auch zu erreichen. Falls dieser Wert zu klein eingestellt ist wird die Tür ihre Geschwindigkeiten nicht erreichen',
  accelerationTitle: 'Beschleunigung',
  acceptTerms: 'Ich akzeptiere die Nutzungsbedingungen',
  add: 'Hinzufügen',
  addBuilding: 'Gebäude hinzufügen',
  addLift: 'Aufzug hinzufügen',
  addressHint:
    'Geben Sie den Firmen- oder Gebäudenamen in die Suche ein, um Adress- und Bildvorschläge zu erhalten.',
  adjustCameraSettings:
    'Sie können auch die Kameraeinstellungen über das Dropdown-Menü anpassen.',
  admin: 'Administration',
  alreadyHasSubcontractorsError:
    'Als Subunternehmer können Sie keine weiteren Subunternehmer haben. Bitte entfernen Sie vorhandene Subunternehmer, bevor Sie diese Einladung annehmen.',
  all: 'Alle',
  allBuildings: 'Alle Gebäude',
  allBuildingsSubscription: 'Von allen Gebäuden',
  alphabetical: 'Alphabetisch',
  ambientTemperature: 'Gehäusetemperatur',
  assetNumber: 'Anlagennummer',
  assetNumberHint:
    'Bitte geben Sie die Anlagennummer ein, sofern Sie diese zur Hand haben.',
  auto: 'Auto',
  back: 'Zurück',
  backToDashboard: 'Zurück zum Dashboard',
  belt_tension: 'Riemenspannung',
  belt_tension_actions:
    'Die Riemenspannung muss laut Installationsanleitung eingestellt werden.',
  beltclamp: 'Riemenklemme',
  belt_tension_description: 'Die Riemenspannung ist zu hoch/gering.',
  bottom: 'unten',
  bottomToTop: 'Von unten nach oben',
  boxLinkedToOtherLift: (lift: string, building: string) =>
    `Die Box ist bereits mit ${lift} in ${building} verknüpft. Fortfahren?`,
  boxNotReady: 'Die ausgewählte Box ist nicht verfügbar.',
  boxOfflineHint:
    'Wenn Sie eine der unten aufgeführten Boxen aktualisieren möchten, verbinden Sie sie bitte zuerst mit dem Internet.',
  boxScannedNoIdFound: 'Box gescannt, aber keine ID gefunden.',
  boxSoftware: 'Software',
  boxSoftwareStatusError:
    'Der Status der Boxen konnte nicht abgerufen werden: Bitte überprüfen Sie die Internetverbindung der Box und versuchen Sie es erneut. Wenn das Problem weiterhin besteht, wenden Sie sich bitte an Ihren Wittur-Ansprechpartner.',
  boxSoftwareTitle: 'Box Software',
  boxSoftwareUpdateError:
    'Fehler beim Aktualisieren der Box-Software: Bitte überprüfen Sie die Internetverbindung der Box und versuchen Sie es erneut. Wenn das Problem weiterhin besteht, wenden Sie sich bitte an Ihren Wittur-Ansprechpartner.',
  box_id: 'Box ID',
  brakeParDescription:
    'Durch Einstellen dieses Parameter wird die Verzögerung und das Kriechen zum Offen / Geschlossen Ende beeinflußt (Voreingestellt auf ‘5’).',
  brakeParNote:
    'Der mögliche Einstellbereich ist ‘0’..’9’, wobei das früheste Anbremsen mit Parameter ‘0’ erreicht wird und das späteste Verzögern mit Parameter ‘9’ erreicht wird.',
  brakeParTitle: 'Bremsparameter',
  buffer: 'Puffer',
  building: 'Gebäude',
  buildingHasNoElevator: 'Dieses Gebäude hat keine Aufzüge.',
  buildingName: 'Name',
  buildings: 'Gebäude',
  buildingsWithoutValidAddress: 'Gebäude ohne gültige Adresse',
  bushingClose: 'Tasterabdeckung schließen',
  bushingOpen: 'Tasterabdeckung öffnen',
  cablechain: 'Kabelkette',
  cableTensorSpring: 'Seilspannfeder',
  callFloorsInstruction: (
    <>
      Öffnen Sie die Tür und schicken Sie den Aufzug{' '}
      <b>in alle anderen Stockwerke</b>. Treten Sie aus dem Fahrkorb und drücken
      Sie "Weiter".
    </>
  ),
  cameraMode: 'Kameramodus',
  cancel: 'Abbrechen',
  car: 'Kabine',
  carDoor: 'Fahrstuhlkorbtür',
  carDoorCounter: 'Türzyklenzähler der bisherigen Türelektronik',
  carDoorModified: 'Änderungen an der Fahrkorbtür vorgenommen',
  cd_counter_rollers_installation: 'Gegenrollen',
  cd_counter_rollers_installation_actions:
    'Prüfen sie die Leichtgängigkeit und Abstand der einzelnen Kabinentürgegenrollen.\n  Lockern sie jene Rollen, die nicht leichtgängig per Hand zu drehen sind oder der Abstand zur Schiene zu groß ist. Stellen sie alle gelockerten Rollen erneut so ein, dass sie so nahe wie möglich zur Schiene stehen, OHNE jedoch direkt Kontakt mit ihr zu haben.\n  Beachten sie bitte die Installationsinformation wenn zb. der Kuppler gelöst werden muss, um eine Rolle zu erreichen.',
  cd_counter_rollers_installation_description:
    'Die Kabinengegenrollen sind nicht korrekt angezogen.',
  cd_roller: 'Kabinenrollen',
  chooseFloorOrCardoor: 'Wähle Stockwerk / Fahrstuhlkorbtür',
  choosePartNumber: 'Teilenummer auswählen',
  chooseReason: 'Wählen Sie einen Grund (optional)',
  chooseStartingFloorInstructions:
    'Bitte wählen Sie das Stockwerk aus, in dem der Testlauf gestartet werden soll. Dies kann wahlweise das oberste oder das unterste Stockwerk sein.',
  clearDriveErrors: 'Löschen',
  close: 'Schließen',
  closeDoorInstructions: (floor: string) => (
    <>
      Begeben Sie sich ins <b>{floor == 'bottom' ? 'unterste' : 'oberste'}</b>{' '}
      Stockwerk. Stellen Sie sicher, dass die Aufzugtüren geschlossen sind.
    </>
  ),
  closeForcePotiDescription:
    'Eingestelltes Kraftlimit welches für die Öffnung und Schließung der Türe verwendet wird.',
  closeForcePotiNote:
    'Via Potentiometer kann dieser Wert auf älteren Elektroniken adjustiert werden. Bei neuere Elektroniken wie MIDIV2 wird dieser Wert hier bei Bedarf im Service Mode adjustiert (im Normalfall ist ein Verändern des Wertes nicht nötig wegen automatischem Selbstlernen).',
  closeForcePotiTitle: 'Schließkraft Limit',
  closeHoldForceDescription:
    'Mit diese Kraft wird im Geschlossenen Ende der Kuppler gehalten.',
  closeHoldForceNote:
    'Diese Kraft sollte normalerweise zwischen 50N and 60N eingestellt werden. Bei höheren Einstellungen kann der Motor Überhitzen und die Elektronik in Reduktion gehen, bei zu kleiner Einstellung kann es zu nicht erwünschten Bewegungen der Tür kommen.',
  closeHoldForceTitle: 'Haltekraft Geschlossen',
  closeSpeedDescription:
    'Maximale Türschließgeschwindigkeit, der Türantrieb wird versuchen diese Geschwindigkeit in der Schließbewegung zu erreichen.',
  closeSpeedNote:
    'Die Wirkliche Schließgeschwindigkeit ist von den DIP Schaltereinstellungen abhängig. DIP Schaltereinstellung auf Geschwindigkeit 4 bedeuted 100% der Maximalen Türschließgeschwindigkeit',
  closeSpeedTitle: 'Schließgeschwindigkeit',
  closing_device_failure: 'Schließeinrichtung',
  closing_device_failure_actions:
    'Kontrollieren sie die Funktion der Schließeinrichtung der betroffenen Schachttüre. Die Schachttüre alleine muss aus jeder Türposition aus eigener Kraft schließen.',
  closing_device_failure_description:
    'Die Schließeinrichtung wurde nicht korrekt installiert. Der Kraftaufwand zum Schließen der Tür ist zu hoch.',
  companyName: 'Firmenname',
  completed: 'Abgeschlossen',
  componentDetails: 'Details der Komponente',
  componentDetailsHint:
    'Für einige Komponenten gibt es mehrere Teilenummern, die ausgewählt werden müssen. Dies ist eine wichtige Information für die Nutzung der präventiven Wartungsfunktion. Bitte klicken Sie auf die aufgelisteten Elemente, um das tatsächlich installierte Teil auszuwählen.',
  confirm: 'Bestätigen',
  confirmDeleteBuilding: 'Möchten Sie dieses Gebäude wirklich löschen?',
  confirmDeleteErrorHistory:
    'Möchten Sie diese Fehlerhistorie wirklich löschen?',
  confirmDeleteFavoriteBuilding:
    'Möchten Sie dieses Gebäude wirklich aus Ihren Favoriten entfernen?',
  confirmDeleteLift: 'Möchten Sie diesen Aufzug wirklich löschen?',
  confirmInvite: 'Bestätigen und Verbinden',
  connectionErrorMessage:
    'Daten können aufgrund eines Verbindungsproblems nicht abgerufen werden. Bitte versuchen Sie es später erneut.',
  continue: 'Weiter',
  contractorBuilding: 'Gebäude des Auftraggebers',
  contractorBuildings: 'Gebäude des Auftraggebers',
  contractors: 'Auftraggeber',
  couplerelease: 'Kupplerentriegelungsrolle',
  couplerFriction: 'Kupplerreibung',
  couplerMovementParameter: 'Kuppler Parameter',
  couplerSpeedDescription:
    'Dieser Wert gibt die maximale Geschwindigkeit des Riemens im Kupplerbereich an.',
  couplerSpeedTitle: 'Kupplergeschwindigkeit',
  couplerWitdhDescription:
    'Dieser Wert gibt die Riemenlänge an, die in den Kupplerbereich fällt. Dieser Wert wird im Einlernvorgang ermittelt',
  couplerWitdhTitle: 'Kupplerweite',
  currentVersion: 'Aktuelle Version',
  couplerwidth: 'Kupplerbreite',
  date: 'Datum',
  delete: 'Löschen',
  deleteErrorHistory: 'Fehlerhistorie löschen',
  directConnection: (
    <>
      <p>
        Verbinden Sie sich mit dem WLAN der ELEVATORSENSE Box, indem Sie den auf
        der Verpackung aufgedruckten QR-Code mit der Kamera-App Ihres Telefons
        scannen.
      </p>
      <p>
        Sobald die Verbindung hergestellt ist, kehren Sie zu diesem Bildschirm
        zurück und klicken Sie unten auf die Schaltfläche <b>Weiter</b>.
      </p>
    </>
  ),
  disconnectProgrammingTool: 'Verbindung trennen',
  divpulley: 'Umlenkrolle',
  documentation: 'Dokumentation',
  doorContact: 'Türkontakt',
  doorCycleCounter: 'Türzyklen',
  doorCycleError1: 'Es wurden keine Daten für diesen Türzyklus empfangen.',
  doorCycleError2: 'Der Türzyklus war gestört.',
  doorCycleError4: 'Es wurde ein Re-Opening der Tür erkannt.',
  doorCycleError5: 'Es wurde ein Re-Closing der Tür erkannt.',
  doorCycleError7: 'Es wurden keine validen Türzyklen empfangen.',
  doorCycles: 'Türzyklen',
  doorFriction: 'Türreibung',
  doorInstallationDate: 'Datum des Türeinbaus',
  doorIsClosed: 'Ja, die Tür ist geschlossen',
  doorModel: 'Türmodell',
  doorModelNotSupported:
    'Das ausgewählte Türmodell ist nicht für die vorbeugende Wartung verfügbar.',
  doorMovementParameter: 'Tür Parameter',
  doorPosition: 'Tür Position',
  doorSerialNumber: 'Seriennummer der Tür',
  doorType: 'Türtyp',
  doorwidth: 'Türbreite',
  downloadCertificate: 'Zertifikat herunterladen',
  downloadLicense: 'Alle Lizenzen als CSV herunterladen',
  downloadRequestedExtendedWarranty:
    'Alle Anträge auf Garantieverlängerung herunterladen',
  drive_motor_noise: 'Motorgeräusch',
  driveesn: 'ESN',
  drivesoftware: 'Firmware',
  editBuilding: 'Gebäude bearbeiten',
  editLift: 'Aufzug bearbeiten',
  elecBoard: 'Türsteuerplatine',
  elevatorIdNotFound: (
    <>
      Um eine erweiterte Garantie anzufordern, müssen Sie die Seriennummer der
      Tür in den Aufzugseinstellungen eingeben.{' '}
      <b>Klicken Sie hier, um zu den Einstellungen zu gelangen.</b>
    </>
  ),
  elevatorInstallationDate: 'Datum der Aufzugsinstallation',
  elevatorUsageType: 'Aufzug Verwendungsart',
  email: 'E-Mail',
  encoderTemperature: 'Encoder-Temperatur',
  error: 'Fehler',
  errorDescription: 'Fehlerbeschreibung',
  errorEarlierThanInstallationDate:
    'Es ist nicht möglich, ein früheres Datum als das Installationsdatum einzugeben.',
  errorInvalid: 'Bitte geben Sie einen gültigen Wert ein.',
  errorInvalidOperation: 'Unzulässige Operation',
  errorMessages: 'Fehlermeldungen',
  errorMessagesDescription: 'Anzeige von protokollierten Fehlermeldungen.',
  errorNotFound: 'Resource nicht gefunden',
  errorRetryCamera:
    'Kamera kann nicht zugegriffen werden. Erneuter Versuch, die Seite wird neu geladen...',
  errorRequired: 'Bitte füllen Sie das Feld aus.',
  errorUnableToAccessCamera:
    'Kamera kann nach mehreren Versuchen nicht zugegriffen werden. Bitte aktualisieren Sie die Seite oder überprüfen Sie die Kameraberechtigungen.',
  errorUnknownOrForbiddenBox:
    'Diese Box ist nicht vorhanden. Bitte kontaktieren Sie Ihren Wittur Service, falls Sie Unterstützung benötigen.',
  errors: 'Fehler',
  errorsEmpty: 'Keine Meldungen',
  errorsEmptyHint:
    'Bitte überprüfen Sie Ihre Filter und aktivieren Sie gegebenenfalls weitere Typen.',
  extendWarranty: 'Garantie verlängern',
  fatalError: 'Kritischer Fehler',
  fatalErrors: 'Kritische Fehler',
  favorite: 'Favorit',
  favorites: 'Favoriten',
  figureAdjustment: 'Einstellvorgang',
  figureAugustaEVO: 'Beispiel Augusta EVO',
  figureClosingWeight: 'Schließgewicht',
  figureEco: 'ECO+',
  figureHydra: 'Beispiel Hydra',
  figureMidi: 'MIDI/SUPRAV2',
  figureNotOk: 'Nicht OK',
  figureOk: 'OK',
  figureSGV: 'Beispiel SGV',
  figureSpirator: 'Spirator',
  figureSpring: 'Feder',
  firstName: 'Vorname',
  floor: 'Stockwerk',
  floors: 'Stockwerke',
  followingBoxScanned: 'Die folgende Box wurde gescannt',
  forgotPassword: 'Passwort vergessen?',
  forgotPasswordText:
    'Geben Sie Ihre E-Mail-Adresse ein, um Ihr Passwort zurückzusetzen.',
  groundFloor: 'Erdgeschoss',
  home: 'Übersicht',
  incompleteElevatorSettings:
    'Um die präventive Wartung nutzen zu können, benötigen wir weitere Informationen zu diesem Aufzug. Bitte gehen Sie zu Ihren Aufzugseinstellungen und geben Sie die fehlenden Informationen ein.',
  initialized: 'Vorbereitet',
  installationControl: 'Installationskontrolle',
  installationControlDescription:
    'Automatische Tests zum Erkennen und Beheben von Installationsfehlern.',
  installationControlExecutedBy: 'Durchgeführt von',
  installationControlHeadline: 'Neuester Installationsstatus',
  installationControlHistory: 'Verlauf',
  installationControlHistoryEmpty: 'Bislang keine Läufe durchgeführt',
  installationControlInstructions: 'Zur Anleitung',
  installationControlSubheadline: 'Zusammenfassung aller Läufe',
  installationDateErrorMessage:
    'Es ist nicht möglich, ein früheres Tür-Installationsdatum als das Installationsdatum des Aufzugs einzugeben',
  instantSubscription: 'Sofort Benachrichtigung',
  instantSubscriptionExplanation: (
    <>
      Die <b>Sofort-Benachrichtigung</b> ist eine unmittelbare Benachrichtigung
      per E-Mail sobald ein kritischer Fehler auf einem Fahrstuhl aufgetreten
      ist. Kritische Fehler sind Fehler, die zum sofortigen Ausfall des
      Fahrstuhls führen können.
    </>
  ),
  invitationExpired: 'Der Einladungs-Link ist nicht mehr gültig.',
  inviteAsManager: 'Nutzer darf andere Nutzer einladen',
  inviteCompanyWithSubcontractorsError:
    'Das Unternehmen, das Sie einladen möchten, hat bereits Subunternehmer und kann​',
  inviteCoworkers: 'Mitarbeiter einladen',
  inviteSubcontractor: 'Subunternehmer einladen',
  inviteToSignUpMessage: (companyName: string) =>
    `Das Unternehmen ${companyName} hat Sie eingeladen, sich bei ElevatorSense als ihr Subunternehmer anzumelden. Bitte füllen Sie das Formular aus, um sich zu registrieren.`,
  inviteToBeSubcontractorMessage: (companyName: string) =>
    `${companyName} hat Sie eingeladen, ihr Subunternehmer in ElevatorSense zu sein.`,
  ipaddress: 'IP-Adresse',
  joinText: (name: string) => (
    <>
      Bitte registrieren Sie sich, um der <i>{name}</i> Organisation
      beizutreten.
    </>
  ),
  landingDoor: 'Schachttür - Stockwerk',
  landing_lock_roller_adjustment: 'Schachttür Hackenriegelrollen Einstellung',
  landingCarDoorSpareParts: 'Schachttür / Fahrstuhlkorbtür Ersatzteile',
  lastError: 'Letzte Fehlermeldung',
  lastExchangeDate: 'Letztes Austauschdatum',
  lastMaintenanceDate: 'Datum der letzten Wartung',
  lastMaintenanceScope: 'Letzter Wartungsumfang',
  lastMaintenanceScopeHint:
    'Wählen Sie den von Wittur empfohlenen Wartungsumfang, der am letzten Wartungstermin tatsächlich durchgeführt wurde.',
  lastName: 'Nachname',
  lastTimeDoorElectronicsChange: 'Datum des Austausches der Türelektronik',
  latestSoftwarePackVersion: (version: string) =>
    `Softwarepaket-Version: ${version}`,
  ld_counter_rollers_installation: 'Gegenrollen',
  ld_counter_rollers_installation_actions:
    'Prüfen sie die Leichtgängigkeit und Abstand der einzelnen Schachttürgegenrollen.\n  Lockern sie jene Rollen, die nicht leichtgängig per Hand zu drehen sind oder der Abstand zur Schiene zu groß ist. Stellen sie alle gelockerten Rollen erneut so ein, dass sie so nahe wie möglich zur Schiene stehen, OHNE jedoch direkt Kontakt mit ihr zu haben.',
  ld_counter_rollers_installation_description:
    'Die Schachttürgegenrollen sind nicht korrekt angezogen.',
  ld_roller: 'Schachttürrollen',
  left: 'Links',
  licenseRequired:
    'Für dieses Produkt wurde noch keine gültige Lizenz erworben. Um das Produkt zu erwerben, kontaktieren Sie bitte Ihren Wittur Ansprechpartner.',
  lift: 'Aufzug',
  liftMovementDistance: 'Zurückgelegte Distanz',
  liftSetup: 'Einrichtung',
  liftSetupComplete: 'Einrichtung abgeschlossen.',
  liftSetupError: 'Die zugehörigen Daten sind unvollständig oder fehlen.',
  liftSetupError40: 'Die Fahrstuhlbewegung passt nicht zum Einrichtungslauf.',
  liftSetupIncomplete: 'Einrichtung unvollständig.',
  liftSetupPending:
    'Bitte führen Sie den Einrichtungslauf zur initialen Einrichtung von ElevatorSense auf Ihrem Fahrstuhl durch. Ohne den Einrichtungslauf können Sie einige Funktionalitäten der ElevatorSense Produkte nicht nutzen bzw. die Ergebnisse der ElevatorSense Produkte können abweichen. Klicken Sie hier, um den Einrichtungslauf durchzuführen.',
  liftSetupRepeat:
    'Der Einrichtungslauf war nicht erfolgreich. Bitte führen Sie den Lauf unter Berücksichtigung der Prozessbeschreibung erneut durch.',
  lifts: 'Aufzüge',
  linkBox: 'Box verknüpfen',
  linkToWebshop: (link: string) => (
    <>
      Wenn Sie eine ElevatorSense-Box haben und eine Lizenz aktivieren möchten,
      klicken Sie bitte{' '}
      <a href={link} className="clickable-link">
        hier
      </a>
      .
    </>
  ),
  loading: 'Lade Daten',
  login: 'Anmelden',
  loginText: 'Bitte melden Sie sich an.',
  logout: 'Abmelden',
  mandatory: 'Prüfung des Austauschs durchführen',
  manageAccess: 'Zugriff verwalten',
  manageElevators: 'Aufzüge verwalten',
  manageSharedBuildingsAndElevator: 'Gemeinsame Gebäude/Aufzüge verwalten',
  manual: 'Manuell',
  maxElectronics: 'Max. Aufzüge',
  maxUsage: 'Nutzungslimit',
  members: 'Mitarbeiter',
  misalignment_cd_vs_ld: 'Ausrichtung Tür/Kuppler',
  misalignment_cd_vs_ld_actions:
    'Das System muss so eingestellt werden, dass das Durchfahrtsspiel zwischen Kuppler und Schachttür-Kupplerrollen auf beiden Seiten im Idealfall gleich ist.',
  misalignment_cd_vs_ld_description:
    'Der Kuppler und die Kabinentür sind nicht korrekt zur Schachttür ausgerichtet.',
  misalignment_cd_vs_ld_sill_gap: 'Türschwellenspalt',
  misalignment_cd_vs_ld_sill_gap_actions:
    'Die Abstände X/Y zwischen den Schwellen links und rechts muss gleich sein (X=Y). Falls dieser Fehler in allen Stockwerken auftritt, ist die Montage der Kabinentüre richtigzustellen.',
  misalignment_cd_vs_ld_sill_gap_description:
    'Die Schachttür ist nicht parallel zur Kabinentür ausgerichtet',
  mode: 'Modus',
  monitoring: 'Diagnose',
  motor: 'Motor',
  motorTemperature: 'Motortemperatur',
  myBuildingsSubscription: 'Nur von meinen Favoriten',
  name: 'Name',
  networkQuality: 'Netzwerkqualität',
  newInstallationControl: 'Neue Installationskontrolle',
  next: 'Vor',
  nextMaintenanceDate: 'Datum der nächsten Wartung',
  no: 'Nein',
  notAuthorizedToConfirmInviteError:
    'Sie sind nicht berechtigt, diese Einladung zu bestätigen, da sie nicht für Ihren angemeldeten Benutzer bestimmt ist.',
  noBoxLinked:
    'Der Lift ist noch mit keiner Box verknüpft. Um alle Funktionen der App zu nuzten, scannen Sie den QR-Code der Box oder geben Sie die Box-ID von Hand ein.',
  noBuildingsVisible:
    'Keine Gebäude sind in der aktuellen Kartenansicht sichtbar. Versuchen Sie, die Karte anzupassen, um weitere Gebäude zu sehen.',
  noComponentsFound: 'Keine Komponenten für dieses Stockwerk gefunden',
  noFavorite: 'Kein Favorit',
  noFavoritesChoosen:
    'Sie haben noch keine Lieblingsgebäude ausgewählt. Navigieren Sie zu Ihrem Gebäude und klicken Sie auf das Sternsymbol, um es zu Ihren Lieblingsgebäuden hinzuzufügen.',
  noInstallationControlFound:
    'Die ESN der Türelektronik stimmt nicht überein mit der ESN des letzten Installation Control Laufs. Bitte prüfen Sie, ob Sie mit dem korrekten Fahrstuhl verbunden sind.',
  noSubcontractors: 'Keine Subunternehmer gefunden.',
  noSubcontractorMessage:
    'Derzeit sind keine Subunternehmer mit Ihrem Unternehmen verknüpft. Um einen zu verknüpfen, senden Sie eine Einladung.',
  noSubscription: 'Keine',
  note: 'Hinweis',
  noQRCodeDetected:
    'Kein QR-Code erkannt. Bitte bewegen Sie die Kamera, damit der QR-Code scharfgestellt und vollständig im Sichtfeld sichtbar ist.',
  nudgingSpeedDescription:
    'Wenn aktiv, wird der Türantrieb versuchen, die Drängelgeschwindigkeit in der Schließbewegung zu erreichen.',
  nudgingSpeedNote:
    'Die Wirkliche Schließgeschwindigkeit ist von den DIP Schaltereinstellungen abhängig. DIP Schaltereinstellung auf Geschwindigkeit 4 bedeuted 100% der Maximalen Drängelgeschwindigkeit.',
  nudgingSpeedTitle: 'Drängelgeschwindigkeit',
  obsolete: 'Obsolet',
  offline: 'Offline',
  offlineConnection:
    'Sie sind zur Zeit offline. Sie können jedoch das Werkzeug zur Programmierung benutzen.',
  ok: 'Reguläre Maintenance',
  online: 'Online',
  openHoldForceDescription:
    'Kraft mit der die Tür im Geöffneten Zustand aufgedrückt wird',
  openHoldForceNote:
    'Diese Kraft sollte normalerweise zwischen 50N and 60N eingestellt werden. Bei höheren Einstellungen kann der Motor Überhitzen und die Elektronik in Reduktion gehen, bei zu kleiner Einstellung kann es zu nicht erwünschten Bewegungen der Tür kommen',
  openHoldForceTitle: 'Haltekraft Geöffnet',
  openSpeedDescription:
    'Maximale Türöffnungsgeschwindigkeit, der Türantrieb wird versuchen diese Geschwindigkeit in der Öffnungsbewegung zu erreichen.',
  openSpeedNote:
    'Die Wirkliche Öffnungsgeschwindigkeit ist von den DIP Schaltereinstellungen abhängig. DIP Schaltereinstellung auf Geschwindigkeit 4 bedeuted 100% der Maximalen Türöffnungsgeschwindigkeit.',
  openSpeedTitle: 'Öffnungsgeschwindigkeit',
  otherReason: 'Anderer Grund',
  overdue: 'Überfällig',
  parameter: 'Parameter',
  partInformation: 'Teileinformationen',
  partIsStillOk: 'Teil ist noch in Ordnung',
  partNumber: 'Teilenummer',
  partName: 'Teilname',
  password: 'Passwort',
  passwordResetSent:
    'Wir haben Ihnen eine E-Mail zum Zurücksetzten des Passworts gesendet. Bitte überprüfen Sie Ihren Posteingang.',
  pendingInvites: 'Ausstehende Einladungen',
  pendingUserInvites: 'Ausstehende Benutzereinladungen',
  pendingSubcontractorInvites: 'Ausstehende Subunternehmer-Einladungen',
  planningAndAlerts: 'Planung und Warnungen',
  position: 'Position',
  positionOutDescription:
    'Es definiert jene Position in welcher das Ausgangsrelais der Elektronik geschaltet wird (FPC Abhängig). Standardeinstellung ist 0 (deaktiviert).',
  positionOutNote:
    'Wird der Parameter gesetzt, schaltet das Relais bei erreichen der Position um. Diese Funktion wird für voreilende Anlagen verwendet.',
  positionOutTitle: 'Positionsschalter',
  postpone: 'Verschieben',
  powerReductionFactor: 'Leistungsreduktion',
  prev: 'Zurück',
  preventiveMaintenance: 'Präventive Wartung',
  preventiveMaintenanceDescription:
    'Tauschen Sie Ihre Fahrstuhltür Ersatzteile aus, bevor sie kaputt gehen.',
  preventiveMaintenanceUnavailable:
    'Die eingegebenen Daten sind vollständig! ElevatorSense berechnet nun den Wartungsplan basierend auf der Nutzung Ihres Elevators. Dieser Vorgang kann einige Minuten dauern.',
  priority: 'Priorität',
  product: 'Produkt',
  programmingDisabledHint:
    'Die Programmierung der Elektronik ist zur Zeit deaktiviert. Bitte stellen Sie sicher, dass sich die Elektronik im Service Drive Mode befindet um die Programming des Gerätes zu ermöglichen.',
  programmingErrorConnection: (
    <>
      <p>
        Wir haben Probleme beim Verbindungsaufbau mit der ELEVATORSENSE Box
        festgestellt.
      </p>
      <p>
        Bitte schalten Sie Ihre "Mobile Datenverbindung" aus und stellen Sie
        sicher, dass Sie nur mit dem WLAN der ELEVATORSENSE Box verbunden sind.
      </p>
      <p>
        Anschließend klicken Sie unten auf die Schaltfläche <b>Weiter</b>.
      </p>
    </>
  ),
  programmingErrorNoPopup:
    'Neues Fenster konnte nicht geöffnet werden. Stellen Sie sicher, dass Popups in Ihrem Browser erlaubt sind.',
  programmingErrorRequest:
    'Anfrage gescheitert. Bitte stellen Sie sicher, dass Ihr Gerät sich im WIFI Netzerk der ELEVATORSENSE Box befindet.',
  programmingTool: 'Wireless Programming',
  programmingToolDescription: 'Einfache Konfiguration der Türelektronik.',
  pulley_is_touching_belt: 'Riemen streift an der Rolle',
  pulley_is_touching_belt_actions:
    'Stellen Sie den Riemen so ein, dass er die Bordscheibe nicht berührt.',
  pulley_is_touching_belt_description: 'Der Riemen streift an der Bordscheibe.',
  readyToRunTest: 'Bereit zum Starten des Testlaufs.',
  realtimeMonitoringDescription:
    'Anzeige von Messwerten und Fahrkurven in Echtzeit.',
  realtimeMonitoringTitle: 'Echtzeitdiagnose',
  recommended: 'Prüfung des Austauschs planen',
  recommendedActions: 'Empfohlene Maßnahmen',
  recommendedDateOfExchange: 'Empfohlenes Datum um den Austausch zu planen',
  reload: 'Aktualisieren',
  remove: 'Entfernen',
  reopenTimeDescription:
    'Der Reopen Parameter definiert die Wartezeit nach dem Automatischen Umsteuern im Reopen Fall in der Offenposition, vor dem Erneuten schließen der Tür.',
  reopenTimeNote:
    'Diese Funktion ist in Verwendung, wenn das Umsteuern im Reopen-Fall der Elektronik überlassen wird und nicht dem Liftkontroller.',
  reopenTimeParameter: 'Wiederöffnungszeit Parameter',
  reopenTimeTitle: 'Wiederöffnungszeit Parameter',
  repeatFloor:
    'Bitte wiederholen Sie den Installation Control Prozess für dieses Stockwerk!',
  repeatWholeRun: (
    <>
      Bitte wiederholen Sie den <b>kompletten</b> Installation Control Prozess!
    </>
  ),
  replaced: 'Ausgetauscht',
  replacementWasPostponed: 'Der Austausch wurde verschoben am:',
  requestNewLink: 'Neuen Link anfordern',
  resetPassword: 'Passwort zurücksetzen',
  resetPasswordText: 'Bitte geben Sie ein neues Passwort ein.',
  revoke: 'Widerrufen',
  right: 'Rechts',
  rollerBumper: 'Schlossrolle',
  rollerSynchronisationRope: 'Umlenkrolle Synchro.Seil',
  runs: 'Läufe',
  save: 'Speichern',
  scan: 'Scannen',
  scanned: 'Gescannt',
  scanAgain: 'Erneut scannen',
  searchPlaces: 'Ort suchen',
  select: 'Wähle',
  selectCameraMode: 'Kameramodus auswählen',
  selectDoorModel: 'Wähle Türmodell',
  selectDoorType: 'Türtyp auswählen',
  selectModifiedFloors:
    'Bitte wählen Sie die Stockwerke aus, bei denen seit dem letzten Testlauf Veränderungen vorgenommen wurden. Die Stockwerke, in denen es beim letzten Lauf Fehler gab, sind vorausgewählt.',
  selectPartsHint:
    'Bitte prüfen Sie, welches Teil installiert ist und klicken Sie auf das entsprechende Bild.',
  send: 'Senden',
  sendResults: 'Ergebnis senden',
  sendTo: 'Ergebnis an die folgenden Email-Adressen senden:',
  sensorData: 'Sensordaten',
  serviceDriveSwitch: 'Schalter für Wartungsantrieb',
  settings: 'Einstellungen',
  share: 'Teilen',
  shareBody: (link: string) => `Link zum Testergebnis:\n${link}`,
  shareResults: 'Ergebnis teilen',
  shareSubject: 'ELEVATORSENSE Ergebnis',
  shoe: 'Führungsschuh',
  showPassedTests: 'Fehlerfreie Ergebnisse anzeigen ...',
  showTerms: 'Nutzungsbedingungen anzeigen',
  signUp: 'Registrieren',
  skid: 'Rullo',
  slideingshoe_failure: 'Gleitschuh Fehler',
  slideingshoes_usage: 'Gleitschuh Abnutzung',
  somethingWentWrong:
    'Etwas ist schief gelaufen. Bitte versuchen Sie es erneut.',
  sortBy: 'Sortieren nach',
  sparePartDoesNotFit: 'Ersatzteil passt nicht',
  sparePartNotAvailable: 'Ersatzteil nicht verfügbar',
  speed: 'Geschwindigkeit',
  start: 'Start',
  startNewTestRun: 'Neuen Testlauf starten',
  started: 'Läuft',
  starting_floor: 'Startstockwerk',
  status: 'Status',
  subcontractorNotFound: 'Subunternehmer nicht gefunden',
  subcontractors: 'Subunternehmer',
  subcontractorManagement: 'Subunternehmerverwaltung',
  subscriptionHint:
    'Sie erhalten E-Mail-Benachrichtigungen nur sofern Sie entsprechende Produkte für Ihre ElevatorSense Box gekauft haben.',
  subscriptionText:
    'Bitte wählen Sie welche E-Mail-Benachrichtigungen Sie erhalten möchten.',
  subscriptions: 'Benachrichtigungen',
  summarySubscription: 'Tägliche Zusammenfassung',
  summarySubscriptionExplanation: (
    <>
      Die <b>tägliche Zusammenfassung</b> umfasst eine E-Mail mit einer
      Übersicht über alle ausgewählten Gebäude und Fahrstühle mit der Angabe der
      Anzahl an Fehlern.
    </>
  ),
  temperature: 'Temperatur',
  testCompleted: 'Test abgeschlossen.',
  testResults: 'Testergebnis',
  time: 'Uhrzeit',
  tooHigh: 'Zu hoch',
  tooLoose: 'Zu locker',
  tooLow: 'Zu gering',
  tooTight: 'Zu fest',
  top: 'oben',
  topToBottom: 'Von oben nach unten',
  touching: 'streifend',
  transformer: 'Transformator',
  truckRoller: 'Laufrolle',
  truckRollerInferior: 'Laufrolle minderwertig',
  truckRollerSuperior: 'Laufrolle superior',
  twisted_cd_panels: 'Verdrehte Kabinenpanele',
  twisted_ld_panels: 'Verdrehte Schachtpanele',
  unlinkBox: 'Box trennen',
  unreadNotifications: (n: number) =>
    `${n} ungelesene Benachrichtigung${n === 1 ? '' : 'en'}`,
  unshare: 'Freigabe aufheben',
  update: 'Aktualisieren',
  updateRequired: 'Aktualisierung erforderlich',
  updateStaticData: 'Daten aktualisieren',
  updating: 'Aktualisiert......',
  upToDate: 'Aktuell',
  usage: 'Bisherige Nutzung',
  usedElectronics: 'Genutzte Aufzüge',
  validFrom: 'Gültig von',
  validUntil: 'Gültig bis',
  vibration: 'Vibration',
  viewErrorLog: 'Fehlerprotokoll anzeigen',
  waitWhileInitializingTest: 'Der Test wird initialisiert. Bitte warten ...',
  waitingForData: 'Warten auf neue Daten',
  waitingForTestResults: 'Warten auf das Testergebnis ...',
  warning: 'Warnung',
  warnings: 'Warnungen',
  warrantyExtensionRequestedOn: (date: string) =>
    `Garantieverlängerung wurde am ${date} beantragt`,
  whyNotReplaced: 'Warum wurde die Komponente nicht ersetzt?',
  wifiScanned: 'WIFI wurde gescannt',
  years: 'Jahre',
  yes: 'Ja',
  zero_position: 'Nullposition',
  zero_position_description:
    'Die Nullposition des Türantriebs wurde falsch angelernt.',
  zero_position_eco_actions:
    '1. S1DIP1 = EIN (Service mode)\n2. schieben sie die Türe per Hand zu (Nullposition)\n3. drücken sie den Lerntaster >10s (Longlearn)\n4. Öffnen und Schließen sie die Türe per Servicedrive Taster\n5. halten sie die Taster in den Enden länger gedrückt\n6. Nach 2 Zyklen muss die State LED erlöschen',
  zero_position_midi_actions:
    '1. schieben sie die Türe per Hand zu (Nullposition)\n2. Piktogramm befolgen:',
  yourBuilding: 'Ihr Gebäude',
  yourBuildings: 'Ihre Gebäude',
};
